@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap");

* a {
  text-decoration: blue;
}

body {
  font-family: "Raleway", sans-serif;
}

/* Login Component */
.sign-in-form {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.sign-in-inner {
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  margin-bottom: 2rem;
  background: #ffffff;
  box-shadow: 3px 8px 12px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  transition: all 0.3s;
  width: 60%;
}

.sign-in-inner .sign-in-submit-btn {
  width: 100%;
  border-radius: 12px;
  color: white;
  background-color: #0033cc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.sign-in-inner .sign-in-submit-btn:hover {
  background-color: #005eff;
  cursor: pointer;
}
/* display for term and condition and policies */
.column-with {
  background-color: #f6391c;
  color: white;
  font-size: 10px;
  display: flex;
  align-items: center;
  border-top-right-radius: 0px;
  clip-path: polygon(6% 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.column-with h3 {
  margin-left: 30px;
  font-size: 30px;
  font-weight: bold;
  transition: font-size 0.3s ease-out;
}

.column-image {
  position: relative;
  padding: 0;
  background-color: #0033cc;
  height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 0px;
  overflow: hidden;
  clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);
}

.column-image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
/* Sign-Up Component */
.column-with-label {
  background-color: #f6391c;
  color: white;
  font-size: 10px;
  display: flex;
  align-items: center;
  border-top-right-radius: 24px;
  clip-path: polygon(6% 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.column-with-label h3 {
  margin-left: 30px;
  font-size: 30px;
  font-weight: bold;
  transition: font-size 0.3s ease-out;
}

.column-with-image {
  position: relative;
  padding: 0;
  background-color: #0033cc;
  height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 24px;
  overflow: hidden;
  clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);
}

.column-with-image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.inner {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background: #ffffff;
  box-shadow: 3px 8px 12px rgba(0, 0, 0, 0.3);
  border-radius: 24px;
  transition: all 0.3s;
  width: 75%;
}

.inner .register-submit-btn {
  width: 20vw;
  border-radius: 12px;
  color: white;
  background-color: #0033cc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.inner .register-submit-btn:hover {
  background-color: #005eff;
  cursor: pointer;
}

.outer .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.input-group-text {
  margin-top: 12px;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.terms-and-conditions {
  text-align: center;
  padding-top: 50px;
}

.terms-and-conditions-inner {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  background: #ffffff;
  box-shadow: 3px 8px 12px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  transition: all 0.3s;
  width: 60%;
}

.scrollable-container {
  margin: auto;
  width: 80%;
  height: 500px;
  overflow: auto;
}

.terms-justify {
  text-align: justify;
  padding: 10px;
}
.terms-justify h1 {
  text-align: center;
  padding: 10px;
}

/* Scanner Component */
.scanner {
  margin: 30px;
  padding: 0px;
  text-align: center;
}

.scanner-inner {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  background: #ffffff;
  box-shadow: 3px 8px 12px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  transition: all 0.3s;
  padding-bottom: 2px;
  width: 60%;
  height: auto;
}

.scanner .scanner-logout-btn {
  width: 100%;
  border-radius: 12px;
  color: white;
  background-color: #0033cc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.sign-in .scanner-scan-btn {
  width: 100%;
  border-radius: 12px;
  color: white;
  background-color: #0033cc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success {
  color: green;
  margin-top: 10px;
}

.error {
  color: red;
  margin-top: 10px;
}

/* Reusable CSS Style for Validation for Input */
.invalid input {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.invalid input:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}

.error-text {
  color: #d8000c;
  font-size: 0.75rem;
  margin-top: 5px;
  position: absolute;
}

.invalidSelect {
  border: 1px solid #b40e0e !important;
  background-color: #fddddd !important;
}

.invalidSelect:focus {
  border-color: #ff8800 !important;
  background-color: #fbe8d2 !important;
}

/* Other Reusable CSS  */
.form-control {
  margin-top: 0.75rem;
  position: relative;
}

.row {
  margin-top: 16px;
  margin-bottom: 24px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 2px;
  padding-bottom: 0px;
}

.row-header {
  padding: 0px;
}

/* Styles for extra small screens */
@media screen and (max-width: 576px) {
  .inner {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    background: #ffffff;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3);
    border-radius: 0px;
    /* transition: all 0.3s; */
    width: 100%;
  }
  .column-with-image {
    position: relative;
    padding: 0;
    background-color: #0033cc;
    height: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 0px;
    overflow: hidden;
    clip-path: none;
  }
  .column-image {
    position: relative;
    padding: 0;
    background-color: #0033cc;
    height: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 0px;
    overflow: hidden;
    clip-path: none;
  }
  .column-with {
    border-top-right-radius: 0px;
    clip-path: none;
    justify-content: center;
    padding: 4px;
  }

  .column-with-label {
    border-top-right-radius: 0px;
    clip-path: none;
    justify-content: center;
    padding: 4px;
  }

  .column-with-label h3 {
    font-size: 24px;
    margin-left: auto;
    margin-right: auto;
  }

  .inner .register-submit-btn {
    width: 30vw;
  }

  .sign-in-inner {
    width: 100%;
  }

  .sign-in-inner .sign-in-submit-btn {
    width: 100%;
  }

  .scanner-inner {
    width: 100%;
  }

  .form-control {
    margin-top: 0.75rem;
    margin-bottom: 1rem;
    position: relative;
  }

  .error-text {
    margin-top: -13px;
    position: absolute;
  }

  .input-group-text {
    margin-top: -3px;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .terms-and-conditions-inner {
    width: 90%;
  }
}

/* Styles for small screens*/
@media screen and (min-width: 576px) and (max-width: 768px) {
  .inner {
    /* margin-left: auto;
    margin-right: auto;*/
    margin-top: 0px;
    margin-bottom: 0px;
    background: #ffffff;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3);
    border-radius: 0px;
    transition: all 0.3s;
    width: 100%;
  }

  .column-with-image {
    position: relative;
    padding: 0;
    background-color: #0033cc;
    height: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 24px;
    overflow: hidden;
    clip-path: none;
  }
  .column-image {
    position: relative;
    padding: 0;
    background-color: #0033cc;
    height: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 0px;
    overflow: hidden;
    clip-path: none;
  }
  .column-with {
    border-top-right-radius: 0px;
    clip-path: none;
    justify-content: center;
    padding: 4px;
  }

  .column-with-label {
    /* border-top-right-radius: 24px; */
    clip-path: polygon(6% 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  .column-with-label h3 {
    margin-left: 20px;
    font-size: 30px;
  }

  .inner .register-submit-btn {
    width: 30vw;
  }

  .sign-in-inner {
    width: 100%;
  }

  .sign-in-inner .sign-in-submit-btn {
    width: 100%;
  }

  .scanner-inner {
    width: 90%;
  }

  .form-control {
    margin-top: 0.75rem;
    margin-bottom: 1rem;
    position: relative;
  }

  .error-text {
    margin-top: -13px;
    position: absolute;
  }

  .input-group-text {
    margin-top: -3px;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .terms-and-conditions-inner {
    width: 90%;
  }
}

@media screen and (min-width: 769px) and (max-width: 991px) {
  .form-control {
    margin-top: 0.75rem;
    margin-bottom: 1rem;
    position: relative;
  }

  .error-text {
    margin-top: -13px;
    position: absolute;
  }

  .input-group-text {
    margin-top: -3px;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}
.modal-body {
  text-align: center;
  text-transform: uppercase;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  /* margin-bottom: 30px; */
}
.modal-context {
  font-size: 16px;
  text-transform: uppercase;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
.modal-image {
  text-align: center;
}
.modal-note {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  color: red;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.modalClosebtn {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  /* width: 100%; */
}
mark {
  background-color: yellow;
}
